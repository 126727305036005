// pages/token-info.js
import { useEffect, useState } from 'react';
import Navbar from './components/Navbar';
import axios from 'axios';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardText, Row, Container, CardHeader, CardBody, Col, CardImg, CardFooter, Progress } from 'reactstrap';
import withAdminAuth from '../hoc/withAdminAuth'; // Importe o HOC
import fundo from '../imagens/fundo3.png';

function TokensInfo(props) {
  const [token] = useState(localStorage.getItem('token'));
  const [showToast, setShowToast] = useState(false);
  const [toastTitle, setToastTitle] = useState("");
  const [toastText, setToastText] = useState("");
  const [bgClass, setBgClass] = useState("");
  const [tokensInfo, setTokensInfo] = useState([]);

  const [balances, setBalances] = useState({});

  const { isAdmin, wallet } = props;

  const fetchTokensInfo = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}getTokensInfo.php`);
      setTokensInfo(response.data.tokeninfo);
      console.log(response.data.tokeninfo);
    } catch (error) {
      console.error("Erro ao buscar monsters:", error);
    }
  };

  // Define a função para esconder o Toast automaticamente
  const hideToast = () => setShowToast(false);

  useEffect(() => {
    fetchTokensInfo(); // Executa imediatamente após o componente ser montado
    const interval = setInterval(fetchTokensInfo, 30000); // Configura para executar a cada 30 segundos

    return () => clearInterval(interval); // Limpa o intervalo quando o componente for desmontado
  }, []);

  const styleBack = {
    backgroundImage: `url(${fundo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundAttachment: 'fixed',
    paddingBottom: 20,
    minHeight: '100vh'
  }

  const styleRow = {
    backgroundColor: 'rgb(255,255,255,0.8)',
    padding: '10px 10px 20px 10px',
    marginTop: '0px',
    borderRadius: '5px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)'
  }
  function importAll(r) {
    let images = {};
    r.keys().map((item) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importAll(require.context('../imagens/collection/', false, /\.(bmp|png|jpe?g|svg)$/));
  const imagePath = (id) => require(`../imagens/collection/${id}.bmp`);
  const ids = [47012, 47013, 47014, 47015, 47016, 47017, 47021, 47022, 47023, 47024, 47028];
  const pool = [9773448,76409055988,3084753,603482,15209,0.32,942,8880,171,1.39,37021];
  const getColor = (value) => {
    if (value < 25) {
      return "success"; // Verde para valores abaixo de 25%
    } else if (value < 50) {
      return "info"; // Azul para valores entre 25% e 50%
    } else if (value < 75) {
      return "warning"; // Amarelo para valores entre 50% e 75%
    } else {
      return "danger"; // Vermelho para valores acima de 75%
    }
  };

  return (
    <div style={styleBack}>
      <Navbar isAdmin={isAdmin} balances={balances} wallet={wallet}/>
      <Container className="mt-4">
        <Row className="text-center justify-content-center">
          {tokensInfo.length > 0 && tokensInfo.map((token, index) => (
            <Col md={4} key={token.nameid} className="mb-4">
              <Card>
                <CardHeader><b>{token.ticker} - {token.nameid}</b></CardHeader>
                <CardBody>
                  <Row style={{display: 'flex', justifyContent: 'center'}}>
                    <Col md={2}>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                        <img  
                          src={imagePath(token.nameid)} 
                          alt={`Imagem ${token.nameid}`} 
                          style={{ width: '75px', height: 'auto' }}
                        />
                      </div>
                    </Col>
                    <Col md={8}>
                      <CardText><b>Inventário:</b> {token.inventory}</CardText>
                      <CardText><b>Estorage:</b> {token.storage}</CardText>
                    </Col>
                  </Row>
                  <Row>
                    <CardText><b>POOL</b></CardText>
                    <CardText>{token.text}</CardText>
                    <CardText>({token.pool})</CardText>
                  </Row>
                </CardBody>
                <CardFooter>
                  
                  <Progress className="my-2" value={(token.value / token.pool)*100.0} max="100" animated color={getColor((token.value / token.pool)*100.0)} style={{ height: '25px' }}>
                    <b>{((token.value / token.pool)*100.0).toFixed(2)}%</b>
                  </Progress>
                </CardFooter>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );

}


export default withAdminAuth(TokensInfo); // Use o HOC
