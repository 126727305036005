//./components/Balance-Card.js
import React, {useState, useEffect}from "react";
import {
  Card,
  CardBody,
  CardText
} from "reactstrap";
import axios from 'axios'; // Não esqueça de importar axios

const OnlineCard = () => {
  const [online, setOnline] = useState(0);

  const fetchOnlineUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}getOnlineUsers.php`);
      setOnline(response.data.online); // Supondo que response.data.online seja o número de usuários online
    } catch (error) {
      console.error("Erro ao buscar Online Users:", error);
    }
  };

  useEffect(() => {
    fetchOnlineUsers(); // Executa imediatamente após o componente ser montado
    const interval = setInterval(fetchOnlineUsers, 30000); // Configura para executar a cada 30 segundos

    return () => clearInterval(interval); // Limpa o intervalo quando o componente for desmontado
  }, []);

  return (

    <Card className="my-2 p-0" inverse style={{ margin: 5, backgroundColor: "#00000099", borderRadius: 25, padding: 5, boxShadow: 'inset 0px 0px 2px #ffffff66' }}>
      <CardBody style={{padding: "0"}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', padding: 5}}>
              <div style={{position: 'absolute', top: '-16px', left: '50%', transform: 'translateX(-50%)', fontWeight: 'bold'}} className='text-outline-online'>Online</div>
              <CardText style={{fontSize: "1em", fontWeight: "bold", paddingLeft: 15, paddingRight: 15, color: 'greenyellow'}}>
                  {online}
              </CardText>
          </div>
      </CardBody>
    </Card>

  );
};

export default OnlineCard;