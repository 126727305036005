//Routes.js
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Dashboard from './pages/dashboard';
import CompraRops from './pages/comprarops';
import Profile from './pages/profile';
import Login from './pages/login';
import Register from './pages/register';
import PasswordRecovery from './pages/password-recovery';
import ResetPassword from './pages/reset-password';
import AtivarConta from './pages/ativar-conta';
import AdminDashboard from './pages/admin-dashboard';
import AdminTokensInfo from './pages/token-info';
import AdminCashShopSales from './pages/cash-shop-sales';
import AdminUsers from './pages/admin-users';
import AdminSaques from './pages/admin-saques';

function Router() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/compraRops" element={<CompraRops />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot" element={<PasswordRecovery />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/ativar-conta" element={<AtivarConta />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/admin/tokensinfo" element={<AdminTokensInfo />} />
        <Route path="/admin/cashshopsales" element={<AdminCashShopSales />} />
        <Route path="/admin/users" element={<AdminUsers />} />
        <Route path="/admin/saques" element={<AdminSaques />} />
      </Routes>
    </BrowserRouter>
    
  );
}

export default Router;

