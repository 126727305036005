//login.js
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter, Form, FormGroup } from 'reactstrap';
import fundo from '../imagens/fundo3.png';
import logo from '../imagens/logo.png';
import LoginButton from './components/Login-Button';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [confirmEmail, setConfirmEmail] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [gender, setGender] = useState('');
  const [birthdate, setBirthdate] = useState('2000-01-01');

  const handleSignupSubmit = async (e) => {
    e.preventDefault();

    // Verificação de validade do e-mail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Verificação de validade do e-mail
    if (!emailRegex.test(email) || !emailRegex.test(confirmEmail)) {
      setError('Por favor, insira um e-mail válido.');
      return;
    }

    // Verificação de coincidência de e-mail
    if (email !== confirmEmail) {
      setError('Os e-mails não coincidem.');
      return;
    }

    // Verificação de coincidência de senha
    if (password !== confirmPassword) {
      setError('As senhas não coincidem.');
      return;
    }

    // Verificação de gênero selecionado
    if (!gender) {
      setError('Por favor, selecione um gênero.');
      return;
    }

    // Verificação de idade (mais de 10 anos)
    const birthDate = new Date(birthdate);
    const currentDate = new Date();
    const ageDiff = currentDate.getFullYear() - birthDate.getFullYear();
    if (ageDiff < 10 || (ageDiff === 10 && currentDate < new Date(birthDate.setFullYear(currentDate.getFullYear())))) {
      setError('Usuários devem ter mais de 10 anos de idade.');
      return;
    }

    setError('');
    const res = await fetch(`${process.env.REACT_APP_API_ADDRESS}register.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        username: username,
        email: email,
        password: password,
        gender: gender,
        birthdate: birthdate
      }),
    });

    if (res.ok) {
      const { token, decoded } = await res.json(); // Obter o token da resposta
      localStorage.setItem('token', token); // Armazenar o token no localStorage
      localStorage.setItem('decoded', decoded); // Armazenar o token no localStorage
      navigate('/dashboard');
    } else {
      const { message } = await res.json();
      setError(message);
    }
  };
  const textBoxStyle = {
    backgroundColor: 'white',
    borderRadius: '25px',
    height: '40px',
    minWidth: '300px',
    padding: '15px 30px 15px 30px',
  };
  const selectBoxStyle = {
    backgroundColor: 'white',
    borderRadius: '25px',
    height: '40px',
    minWidth: '300px',
    padding: '5px 30px', // O padding vertical é geralmente menor para selects
    WebkitAppearance: 'none', // Remove o estilo padrão em navegadores WebKit
    MozAppearance: 'none',    // Remove o estilo padrão em navegadores Firefox
    appearance: 'none',       // Remove o estilo padrão
    border: '1px solid #ddd', // Adicione uma borda se desejar
  };
  const textFooterBox = {
    backgroundColor: 'rgb(255,255,255, 0.5)',
    borderRadius: '5px',
    height: '40px',
    minWidth: '300px',
    padding: '7px 5px 5px 5px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
    color: 'black',
    fontWeight: '500',
  };

  return (
    <section className="vh-100" style={{ backgroundImage: `url(${fundo})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <Container className="py-5 h-100">
          <Row className="d-flex justify-content-center align-items-center h-100">
          <Col className="col-12 col-md-8 col-lg-6 col-xl-4">
              <Card className="card text-white text-center align-items-center" style={{ borderRadius: '0.5rem', backgroundColor: 'rgba(0, 0, 0, 0)', borderColor: 'rgba(0, 0, 0, 0)' }}>
              <CardHeader style={{border: '0px'}}><img src={logo} alt="Logo" className='responsive-logo'/></CardHeader>
              <CardBody className="p-4" style={{ borderColor: 'rgba(0, 0, 0, 0)' }}>
                  <Form onSubmit={handleSignupSubmit}>
                  <FormGroup className="mb-3">
                      <input type="text" className="form-control form-control-sm" id="username" placeholder="Username" required style={textBoxStyle} onChange={(e) => setUsername(e.target.value)}/>
                  </FormGroup>
                  <FormGroup className="mb-3">
                      <input type="password" className="form-control form-control-sm" id="password" placeholder="Password" required style={textBoxStyle} onChange={(e) => setPassword(e.target.value)}/>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <input type="password" className="form-control form-control-sm" id="confirmPassword" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required style={textBoxStyle}/>
                  </FormGroup>
                  <FormGroup className="mb-3">
                      <input type="email" className="form-control form-control-sm" id="email" placeholder="E-mail" required style={textBoxStyle} onChange={(e) => setEmail(e.target.value)}/>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <input type="email" className="form-control form-control-sm" id="confirmEmail" placeholder="Confirm E-mail" value={confirmEmail} onChange={(e) => setConfirmEmail(e.target.value)} required style={textBoxStyle}/>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <select className="form-control form-control-sm" id="gender" value={gender} onChange={(e) => setGender(e.target.value)} required style={selectBoxStyle}>
                      <option value="">Select Gender</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </select>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <input type="date" className="form-control form-control-sm" id="birthdate" value={birthdate} onChange={(e) => setBirthdate(e.target.value)} required style={textBoxStyle} placeholder="YYYY-MM-DD"/>
                  </FormGroup>
                  <LoginButton btText={"Register"}/>
                  {error && <div className="alert alert-danger" role="alert">{error}</div>}
                  </Form>
              </CardBody>
              <CardFooter style={textFooterBox}>
                  <p className="mb-0">Already have an account? <Link className="text-black-50 fw-bold" to="/login">Login</Link></p>
              </CardFooter>
              </Card>
          </Col>
          </Row>
      </Container>
      </section>

  );
};

export default Register;
