// pages/token-info.js
import { useEffect, useState } from 'react';
import Navbar from './components/Navbar';
import axios from 'axios';
import { Card, CardText, Row, Container, CardHeader, CardBody, Col, CardImg, CardFooter, Progress } from 'reactstrap';
import withAdminAuth from '../hoc/withAdminAuth'; // Importe o HOC
import fundo from '../imagens/fundo3.png';

function CashShopSales(props) {
  //const [token] = useState(localStorage.getItem('token'));
  const [showToast, setShowToast] = useState(false);
  //const [toastTitle, setToastTitle] = useState("");
  //const [toastText, setToastText] = useState("");
  //const [bgClass, setBgClass] = useState("");
  const [shopCashSales, setShopCashSales] = useState([]);

  const [balances, setBalances] = useState({});

  const { isAdmin, wallet } = props;

  const fetchShopCashSales = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}getShopCashSales.php`);
      setShopCashSales(response.data.cashShopSales);
    } catch (error) {
      console.error("Erro ao buscar monsters:", error);
    }
  };

  // Define a função para esconder o Toast automaticamente
  const hideToast = () => setShowToast(false);

  useEffect(() => {
    fetchShopCashSales(); // Executa imediatamente após o componente ser montado
    const interval = setInterval(fetchShopCashSales, 30000); // Configura para executar a cada 30 segundos

    return () => clearInterval(interval); // Limpa o intervalo quando o componente for desmontado
  }, []);

  const styleBack = {
    backgroundImage: `url(${fundo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundAttachment: 'fixed',
    paddingBottom: 20,
    minHeight: '100vh'
  }

  const styleRow = {
    backgroundColor: 'rgb(255,255,255,0.8)',
    padding: '10px 10px 20px 10px',
    marginTop: '0px',
    borderRadius: '5px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)'
  }
  function importAll(r) {
    let images = {};
    r.keys().map((item) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  function findImage(name) {
    try {
      return require(`../imagens/collection/${name}.bmp`);
    } catch (e) {
      try {
        return require(`../imagens/collection/${name.toLowerCase()}.bmp`);
      } catch (e) {
        console.error("Imagem não encontrada:", name);
        return null; // ou um placeholder de imagem
      }
    }
  }
  
  const images = importAll(require.context('../imagens/collection/', false, /\.(bmp|png|jpe?g|svg)$/));
  const imagePath = (id) => require(`../imagens/collection/${id}.bmp`);
  const getColor = (value) => {
    if (value < 25) {
      return "success"; // Verde para valores abaixo de 25%
    } else if (value < 50) {
      return "info"; // Azul para valores entre 25% e 50%
    } else if (value < 75) {
      return "warning"; // Amarelo para valores entre 50% e 75%
    } else {
      return "danger"; // Vermelho para valores acima de 75%
    }
  };


  return (
    <div style={styleBack}>
      <Navbar isAdmin={isAdmin} balances={balances} wallet={wallet}/>
      <Container className="mt-4">
        <Row className="text-center justify-content-center">
          {shopCashSales.length > 0 && shopCashSales.map((id, index) => (
            <Col md={4} key={index} className="mb-4">
              <Card>
                <CardHeader><b>{shopCashSales[index].nome}</b></CardHeader>
                <CardBody style={{display: 'flex', justifyContent: 'center',}}>
                <CardImg
                  top
                  src={findImage(shopCashSales[index].nome)}
                  alt={`Imagem ${shopCashSales[index].nome}`}
                  style={{ maxWidth: '50px', height: 'auto' }}
                />
                </CardBody>
                <CardFooter>
                  <CardText><b>Lucro Total:</b> {shopCashSales[index].lucro_total}</CardText>
                  <CardText><b>Quantidade:</b> {shopCashSales[index].quantidade}</CardText>
                  {/* ... Outros elementos ... */}
                </CardFooter>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>

  );
}


export default withAdminAuth(CashShopSales); // Use o HOC
