import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LogoutButton from './Logout-Button';
import withAuth from '../../hoc/withAuth';
import BalanceCard from './Balance-Card';
import OnlineCard from './OnlineUsers';
import {
  Collapse,
  Navbar as RSNavbar,
  NavbarToggler,
  NavbarBrand,
  NavbarText,
} from 'reactstrap';

import ConnectWalletButton from './ConnectWalletButton';
import logoImage from '../../imagens/logo.png';
import '../css/Navbar.css'; // Certifique-se de ter este arquivo CSS na pasta correta
import Navitems from './Nav';

const Navbar = (props) => {
  const { isAdmin, balances, wallet } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);
  const toggle = () => setIsOpen(!isOpen);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);

    // Limpa o event listener quando o componente é desmontado
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const floatMenuStyle = {
    position: 'absolute',
    top: '100%', // Posição logo abaixo do Navbar
    paddingTop: '15px',
    paddingBottom: '15px',
    left: 0,
    right: 0,
    backgroundColor: 'rgb(255,255,255, 0.7)', // Cor de fundo do Navbar
    zIndex: 1, // Garante que o menu flutue acima de outros elementos
    display: 'flex-column',
    borderRadius: '5px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
    margin: '0px 40px 0px 40px',
  };

  return (
    isLargeScreen ? 
    <RSNavbar dark expand='md' className='flex-column flex-md-row align-items-center'>
      <NavbarToggler onClick={toggle} className="mr-2"  />
      <div className={`${isLargeScreen ? "d-flex order-1 order-md-1" : "d-flex order-2 order-md-2"} `} >
        <Collapse isOpen={isOpen} navbar style={isOpen && !isLargeScreen ? floatMenuStyle : null}>
          <Navitems isAdmin={isAdmin}/>
        </Collapse>
      </div>
      <NavbarBrand className={`${isLargeScreen ? 'mx-auto order-2 order-md-2' : 'mx-auto order-1 order-md-1'} `} to="/" tag={Link}>
        <img src={logoImage} alt="Logo" style={{ maxWidth: 'auto', height: '65px' }}/>
      </NavbarBrand>
      <NavbarText className={`${isLargeScreen ? 'ml-auto d-flex align-items-center order-md-3 order-3' : 'ml-md-auto order-md-4 order-4'} `}>
        <Collapse isOpen={isOpen} navbar style={isOpen && !isLargeScreen ? floatMenuStyle : null}>
          <ConnectWalletButton wallet={wallet}/>
          <OnlineCard />
          <BalanceCard balance={balances.cashpoints} />
          <LogoutButton />
        </Collapse>
      </NavbarText>
    </RSNavbar>
    :
    <RSNavbar dark expand="md" className="align-items-center" >
      <div className="d-flex justify-content-between align-items-center w-100">
        <NavbarToggler onClick={toggle} className="mr-2"  />
        <NavbarBrand to="/" tag={Link} className="mx-auto">
          <img src={logoImage} alt="Logo" style={{ maxWidth: 'auto', height: '65px' }}/>
        </NavbarBrand>
        <Collapse isOpen={isOpen} navbar className="justify-content-center align-items-center" style={{...isOpen, ...!isLargeScreen ? floatMenuStyle : null}}>
          <Navitems isAdmin={isAdmin} isLargeScreen={isLargeScreen} />
          <NavbarText className="d-flex flex-column justify-content-center align-items-center">
            <ConnectWalletButton wallet={wallet}/>
            <OnlineCard />
            <BalanceCard balance={balances.cashpoints} />
            <LogoutButton/>
          </NavbarText>
        </Collapse>
      </div>
    </RSNavbar>
    
  );
};

export default withAuth(Navbar);