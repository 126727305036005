import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import withAuth from '../../hoc/withAuth';
import {
  Nav,
  NavItem,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { AiOutlineHome } from "react-icons/ai";
import { AiFillRocket } from "react-icons/ai";

import '../css/Navbar.css'; // Certifique-se de ter este arquivo CSS na pasta correta

const Navitems = (props) => {
  const { isAdmin, isLargeScreen } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const noLargeStyle = {
    margin: '0px 20px 5px 20px',
    flexWrap: 'wrap'
  }
  return (
      <Nav className="d-flex justify-content-center" navbar style={!isLargeScreen ? noLargeStyle : null}>
        <NavItem>
            <Link className="nav-link button-fantasy" to="/dashboard"><AiOutlineHome /> Dashboard</Link>
        </NavItem>
        <NavItem>
            <Link className="nav-link button-fantasy" to="/compraRops"><AiFillRocket /> Rops</Link>
        </NavItem>

        {//<NavItem>
            //<Link className="nav-link button-fantasy" to="/profile">Profile</Link>
        //</NavItem>
        }
        {isAdmin === 99 && (
            <Dropdown nav inNavbar isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle nav caret className="nav-link button-fantasy">
              Admin
            </DropdownToggle>
            <DropdownMenu right  style={{backgroundColor: 'rgb(255,255,255,0.5)', border: '0px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)'}}>
              <DropdownItem>
                <Link className="nav-link button-fantasy" to="/admin/users">Users</Link>
              </DropdownItem>
              <DropdownItem>
                <Link className="nav-link button-fantasy" to="/admin/saques">Saques</Link>
              </DropdownItem>
              <DropdownItem>
                <Link className="nav-link button-fantasy" to="/admin/saques">Saques</Link>
              </DropdownItem>
              <DropdownItem>
                <Link className="nav-link button-fantasy" to="/admin/tokensinfo">Tokens Stats</Link>
              </DropdownItem>
              <DropdownItem>
                <Link className="nav-link button-fantasy" to="/admin/cashshopsales">Cash Shop Stats</Link>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        )}
      </Nav>
    
  );
};

export default withAuth(Navitems);