// pages/token-info.js
import { useEffect, useState } from 'react';
import Navbar from './components/Navbar';
import axios from 'axios';
import { Table, Button, TabContent, TabPane, Nav, NavItem, NavLink, Card, CardText, Row, Container, CardHeader, CardBody, Col, CardImg, CardFooter, Progress, Input } from 'reactstrap';
import withAdminAuth from '../hoc/withAdminAuth'; // Importe o HOC
import fundo from '../imagens/fundo3.png';

function ShowSaques(props) {
  const [token] = useState(localStorage.getItem('token'));
  const [showToast, setShowToast] = useState(false);
  const [toastTitle, setToastTitle] = useState("");
  const [toastText, setToastText] = useState("");
  const [bgClass, setBgClass] = useState("");
  const [processados, setProcessados] = useState([]);
  const [aprocessar, setAprocessar] = useState([]);
  const [balances, setBalances] = useState({});

  const { isAdmin, wallet } = props;

  const fetchSaques = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_ADDRESS}getSaques.php`, { token: localStorage.getItem('token') });
      if(response.data.status){
        setProcessados(response.data.processados);
        setAprocessar(response.data.aprocessar);
        console.log();
      }
      
    } catch (error) {
      console.error("Erro ao buscar saques:", error);
    }
  };

  // Define a função para esconder o Toast automaticamente
  const hideToast = () => setShowToast(false);

  useEffect(() => {
    fetchSaques(); // Executa imediatamente após o componente ser montado

  }, []);

  const styleBack = {
    backgroundImage: `url(${fundo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundAttachment: 'fixed',
    paddingBottom: 20,
    minHeight: '100vh'

  }

  const formatter = new Intl.NumberFormat('us-EN', { style: 'decimal' });
  // Função para mostrar os primeiros 5 e os últimos 5 caracteres de uma string
  function formatTxid(txid) {
    if (txid.length <= 10) {
      return txid;
    }
    return `${txid.slice(0, 5)}...${txid.slice(-5)}`;
  }


  // Função para renderizar a tabela de saques processados
function RenderProcessadosTable({ saques }) {
    if (saques.length === 0) return 'Nenuhum saque processado!';
  
    return (
      <Table striped>
        <thead>
          <tr>
            <th>Solicitante</th>
            <th>Token</th>
            <th>Carteira</th>
            <th>TXID</th>
            <th>Quantia</th>
            {/* Outras colunas conforme necessário */}
          </tr>
        </thead>
        <tbody>
          {saques.map((saque) => (
            <tr key={saque.id}>
              <td>{saque.userid}/{saque.name}</td>
              <td>{saque.token}</td>
              <td>{formatTxid(saque.address)}</td>
              <td>{formatTxid(saque.txid)}</td>
              <td>{saque.amount}</td>
              {/* Outros campos conforme necessário */}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
  
  // Função para renderizar a tabela de saques a processar
  function RenderAprocessarTable({ saques }) {
    if (saques.length === 0) return 'Nenhum saque a ser processado!';
  
    return (
      <Table striped>
        <thead>
          <tr>
            <th>Solicitante</th>
            <th>Token</th>
            <th>Carteira</th>
            <th>Quantia</th>
            <th>Data</th>
            {/* Outras colunas conforme necessário */}
          </tr>
        </thead>
        <tbody>
          {saques.map((saque) => (
            <tr key={saque.id}>
              <td>{saque.userid}/{saque.name}</td>
              <td>{saque.token}</td>
              <td>{formatTxid(saque.address)}</td>
              <td>{saque.amount}</td>
              <td>{saque.created_at}</td>
              {/* Outros campos conforme necessário */}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
  
  // Dentro do componente ShowSaques
  return (
    <div style={styleBack}>
      <Navbar isAdmin={isAdmin} balances={balances} wallet={wallet}/>
      <Container className="mt-4">
        <Row style={{backgroundColor: 'white', padding: '10px', marginBottom: '20px', borderRadius: '10px',boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)'}}>
          <h2>Saques a Processar</h2>
          <RenderAprocessarTable saques={aprocessar} />
        </Row>
        <Row style={{backgroundColor: 'white', padding: '10px', marginBottom: '20px', borderRadius: '10px',boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)'}}>
          <h2>Saques Processados</h2>
          <RenderProcessadosTable saques={processados} />
        </Row> 
      </Container>
    </div>
  );
}


export default withAdminAuth(ShowSaques); // Use o HOC
