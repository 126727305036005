// pages/token-info.js
import { useEffect, useState } from 'react';
import Navbar from './components/Navbar';
import axios from 'axios';
import { Table, Button, TabContent, TabPane, Nav, NavItem, NavLink, Card, CardText, Row, Container, CardHeader, CardBody, Col, CardImg, CardFooter, Progress, Input } from 'reactstrap';
import withAdminAuth from '../hoc/withAdminAuth'; // Importe o HOC
import fundo from '../imagens/fundo3.png';

function ShowUsers(props) {
  const [token] = useState(localStorage.getItem('token'));
  const [showToast, setShowToast] = useState(false);
  const [toastTitle, setToastTitle] = useState("");
  const [toastText, setToastText] = useState("");
  const [bgClass, setBgClass] = useState("");
  const [users, setUsers] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [searchTerm, setSearchTerm] = useState('');
  const [balances, setBalances] = useState({});

  const { isAdmin, wallet } = props;

  const fetchUsers = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_ADDRESS}getUsers.php`, { token: localStorage.getItem('token') });
      if(response.data.status){
        setUsers(response.data.users);
      }
      
    } catch (error) {
      console.error("Erro ao buscar monsters:", error);
    }
  };

  // Define a função para esconder o Toast automaticamente
  const hideToast = () => setShowToast(false);

  useEffect(() => {
    fetchUsers(); // Executa imediatamente após o componente ser montado

  }, []);

  const styleBack = {
    backgroundImage: `url(${fundo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundAttachment: 'fixed',
    paddingBottom: 20,
    minHeight: '100vh'

  }
  const calculateVipDaysRemaining = (vipTime) => {
    const currentDate = new Date();
    const vipDate = new Date(vipTime * 1000); // Convertendo de segundos para milissegundos
  
    const differenceInTime = vipDate.getTime() - currentDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); 
  
    return differenceInDays;
  }
  const getGroup = (id, time = 0) => {
    if(id == 99){
      return "GM";
    }else if(id == 5){
      const vipDays = calculateVipDaysRemaining(time);
      if(vipDays < 1){
        return `Normal`;
      }
      return `VIP (Restam ${vipDays} dias)`;
    }else{
      return "Normal";
    }
  }
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedUsers = (() => {
    let sortableUsers = [...users];
    if (sortConfig.key) {
      sortableUsers.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];
  
        // Se a chave for group_id, usa a função getGroup
        if (sortConfig.key === 'group_id') {
          aValue = getGroup(aValue, a.vip_time);
          bValue = getGroup(bValue, b.vip_time);
        }
  
        // Se a chave for cashpoints, converte para número
        if (sortConfig.key === 'cashpoints') {
          aValue = Number(aValue);
          bValue = Number(bValue);
        }
  
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableUsers;
  })();

  const filteredAndSortedUsers = sortedUsers.filter(user => {
    const statusText = getGroup(user.group_id, user.vip_time).toLowerCase();
    const lastLoginFormatted = new Date(user.lastlogin * 1000).toLocaleString().toLowerCase();

    return user.userid.toLowerCase().includes(searchTerm) ||
           statusText.includes(searchTerm) ||
           lastLoginFormatted.includes(searchTerm) ||
           user.num_chars.toString().includes(searchTerm);
  });
  // Conjunto de account_id para excluir
  const excludedAccountIds = new Set([2000004, 2000003, 2000017,2000002,2000000,2000034]);
  const totalCashPoints = users.reduce((accumulator, user) => {
    // Verifica se o account_id atual está na lista de exclusão
    if (!excludedAccountIds.has(user.account_id)) {
      return accumulator + Number(user.cashpoints);
    }
    return accumulator;
  }, 0);
  const formatter = new Intl.NumberFormat('us-EN', { style: 'decimal' });

  return (
    <div style={styleBack}>
      <Navbar isAdmin={isAdmin} balances={balances} wallet={wallet}/>
      <Container className="mt-4">
        <Row className="mb-4">
          <Col md={6}>
            <Card color="warning" style={{boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)'}}>
              <CardHeader>
                <b>Usuários</b>
              </CardHeader>
              <CardBody style={{backgroundColor: "#ffe28a", borderRadius: "0px 0px 5px 5px"}}>
                <CardText>
                {filteredAndSortedUsers.length}
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card color="primary" style={{boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)'}}>
              <CardHeader>
                <b>Rops Circulando</b>
              </CardHeader>
              <CardBody style={{backgroundColor: "#85d6ff", borderRadius: "0px 0px 5px 5px"}}>
                <CardText>
                {formatter.format(totalCashPoints)}
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={2}>
            <Input 
              type="text" 
              placeholder="Search by Username..." 
              value={searchTerm} 
              onChange={handleSearchChange} 
            />
          </Col>
        </Row>
          <Table striped bordered hover responsive className="table-light">
            <thead>
              <tr>
                <th>
                  <Button color="link" onClick={() => requestSort('userid')}>Username</Button>
                </th>
                <th><Button color="link" onClick={() => requestSort('group_id')}>Status</Button></th>
                <th><Button color="link" onClick={() => requestSort('lastlogin')}>Último Login</Button></th>
                <th>Chars</th>
                <th><Button color="link" onClick={() => requestSort('cashpoints')}>ROPs</Button></th>
                {/* Outros cabeçalhos de tabela conforme necessário */}
              </tr>
            </thead>
            <tbody>
            {filteredAndSortedUsers.map((user, index) => (
                <tr key={index}>
                  <td>{user.userid}</td>
                  <td>{getGroup(user.group_id, user.vip_time)}</td>
                  <td>{user.lastlogin}</td>
                  <td>{user.num_chars}</td>
                  <td>{user.cashpoints}</td>
                  {/* Outras células de tabela conforme necessário */}
                </tr>
              ))}
            </tbody>
          </Table>
          {filteredAndSortedUsers.length === 0 && <p>No users found.</p>}
      </Container>
    </div>

  );
}


export default withAdminAuth(ShowUsers); // Use o HOC
