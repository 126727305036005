// pages/dashboard.js
import { useEffect, useState } from 'react';
import Navbar from './components/Navbar';
import axios from 'axios';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardText, Row, Container, CardHeader, CardBody, Col } from 'reactstrap';
import withAuth from '../hoc/withAuth'; // Importe o HOC
import StatusAlert from './components/Status-Alert'; // Importe o componente StatusAlert
import CharCard from './components/CharsCard';
import fundo from '../imagens/fundo3.png';

function Dashboard(props) {
  const [inputValue, setInputValue] = useState('');
  const [token] = useState(localStorage.getItem('token'));
  const [showToast, setShowToast] = useState(false);
  const [toastTitle, setToastTitle] = useState("");
  const [toastText, setToastText] = useState("");
  const [bgClass, setBgClass] = useState("");
  const [chars, setChars] = useState([]);
  const custo = 295;

  const [feito, setFeito] = useState(false);
  const [balances, setBalances] = useState({});

  const { userStatus, isAdmin, wallet } = props;

  const fetchChars = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ADDRESS}getChars.php?token=`+token
      );
      setChars(response.data.data);
      setBalances({ cashpoints: response.data.cashPoints });
      console.log({ cashpoints: response.data.cashPoints });
      
    } catch (error) {
      console.error("Erro ao buscar monsters:", error);
    }
  };

  // Define a função para esconder o Toast automaticamente
  const hideToast = () => setShowToast(false);

  useEffect(() => {
    if (!feito) {
      fetchChars(); // Chame a função fetchChars no useEffect
      setFeito(true);
    }
  }, [feito]);

  const styleBack = {
    backgroundImage: `url(${fundo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundAttachment: 'fixed',
    paddingBottom: 20,
    minHeight: '100vh'
  }

  const styleRow = {
    backgroundColor: 'rgb(255,255,255,0.8)',
    padding: '10px 10px 20px 10px',
    marginTop: '0px',
    borderRadius: '5px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)'
  }

  return (
    <div style={styleBack}>
      <Navbar isAdmin={isAdmin} balances={balances} wallet={wallet}/>
      <Container className="mt-4">
        <Row className="h-100 text-center justify-content-center" fluid="true">
        {chars ? chars.map((char) => (
            <Col md={4}>
              <CharCard char={char} key={char.char_id} />
            </Col>
        )) : (
          <p>Carregando dados...</p>
        )}
        </Row>
      </Container>
    </div>

  );
}


export default withAuth(Dashboard); // Use o HOC
