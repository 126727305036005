import React from 'react';

const LoginButton = ({btText}) => {

  // Estilos do botão Logout
  const buttonStyle = {
    background: 'linear-gradient(to bottom, #1cf4ff 30%, #57a8ff 100%)', // Gradiente de rosa para roxo
    color: '#FFFFFF', // Cor do texto
    border: '2px solid #1cf4ff', // Contorno dourado
    borderRadius: '20px', // Borda arredondada
    padding: '2px 2px', // Espaçamento interno
    fontSize: '18px', // Tamanho da fonte
    fontWeight: 'bold', // Peso da fonte
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.2)', // Sombra do texto
    boxShadow: 'inset 0px 0px 2px #5cf9ff', // Sombra interna escura
    cursor: 'pointer', // Cursor de mão quando passar o mouse sobre o botão
    outline: 'none', // Sem contorno ao focar
    transition: 'background-color 0.3s', // Transição suave para hover
    minWidth: '150px',
    height: '40px',
    marginBottom: '10px',
  };

  return (
    <button 
      type="submit"
      style={buttonStyle}
      onMouseEnter={(e) => (e.target.style.background = 'linear-gradient(to bottom, #1cf4ff 15%, #57a8ff 100%)')} // Escurecer o gradiente ao passar o mouse
      onMouseLeave={(e) => (e.target.style.background = 'linear-gradient(to bottom, #1cf4ff 30%, #57a8ff 100%)')} // Voltar ao gradiente original quando o mouse sair
    >
      {btText}
    </button>
  );
};

export default LoginButton;